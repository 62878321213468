@tailwind base;
@tailwind components;
@tailwind utilities;

/* Fixes Google Chrome autofill background color issue */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
	transition: 5000s ease-in-out 0s;
}

@layer base {
	:root {
		/* Mediakit theme base */
		--kit-base-950: 220 222 224;
		--kit-base-900: 220 222 224;
		--kit-base-800: 121 127 132;
		--kit-base-700: 72 77 81;
		--kit-base-600: 43 46 48;
		--kit-base-500: 37 38 40;
		--kit-base-400: 26 29 30;
		--kit-base-300: 24 26 27;
		--kit-base-200: 14 15 16;
		--kit-base-100: 7 8 8;
		--kit-base-50: 3 7 18;

		/* Mediakit theme primary */
		--kit-primary-50: 236 254 255;
		--kit-primary-100: 207 250 254;
		--kit-primary-200: 165 243 252;
		--kit-primary-300: 103 232 249;
		--kit-primary-400: 34 211 238;
		--kit-primary-500: 6 182 212;
		--kit-primary-600: 8 145 178;
		--kit-primary-700: 14 116 144;
		--kit-primary-800: 21 94 117;
		--kit-primary-900: 22 78 99;
		--kit-primary-950: 8 51 68;

		--kit-light: 165 243 252;
		--kit-medium: 6 182 212;
		--kit-dark: 21 94 117;

		--kit-text: 0 0 0;
		--kit-text-inverse: 255 255 255;
		--kit-background: 255 255 255;

		/* Solis V2 Colors  */
		--sky-1: 11 22 34;
		--sky-2: 14 31 50;
		--sky-3: 24 45 70;
		--sky-4: 24 61 88;
		--sky-5: 19 88 125;
		--sky-6: 5 161 196;
		--sky-7: 48 218 255;
		--sky-8: 157 238 255;
		--sky-9: 226 250 255;

		--sky-alpha-1: rgb(24 70 220 / 5%);
		--sky-alpha-2: rgb(25 129 255 / 11%);
		--sky-alpha-3: rgb(76 148 235 / 19%);
		--sky-alpha-4: rgb(80 169 241 / 28%);
		--sky-alpha-5: rgb(24 155 216 / 50%);

		--green-1: 15 26 22;
		--green-2: 19 39 20;
		--green-3: 22 47 19;
		--green-4: 28 66 28;
		--green-5: 42 96 47;
		--green-6: 54 162 90;
		--green-7: 69 218 119;
		--green-8: 137 255 176;
		--green-9: 197 255 217;

		--green-alpha-1: rgb(89 202 0 / 5%);
		--green-alpha-2: rgb(69 183 0 / 13%);
		--green-alpha-3: rgb(69 183 0 / 18%);
		--green-alpha-4: rgb(65 175 38 / 31%);
		--green-alpha-5: rgb(77 189 75 / 46%);

		--yellow-1: 32 26 20;
		--yellow-2: 42 31 21;
		--yellow-3: 61 43 26;
		--yellow-4: 82 56 27;
		--yellow-5: 109 70 29;
		--yellow-6: 170 93 21;
		--yellow-7: 238 145 59;
		--yellow-8: 244 175 110;
		--yellow-9: 255 212 173;

		--yellow-alpha-1: rgb(187 90 0 / 12%);
		--yellow-alpha-2: rgb(164 89 15 / 20%);
		--yellow-alpha-3: rgb(132 81 30 / 41%);
		--yellow-alpha-4: rgb(132 83 30 / 59%);
		--yellow-alpha-5: rgb(132 83 30 / 81%);

		--red-1: 35 19 28;
		--red-2: 47 19 30;
		--red-3: 73 23 37;
		--red-4: 85 25 42;
		--red-5: 123 14 38;
		--red-6: 183 32 39;
		--red-7: 245 46 56;
		--red-8: 255 122 129;
		--red-9: 255 208 210;

		--red-alpha-1: rgb(212 30 62 / 12%);
		--red-alpha-2: rgb(212 30 62 / 18%);
		--red-alpha-3: rgb(212 36 68 / 31%);
		--red-alpha-4: rgb(212 36 68 / 42%);
		--red-alpha-5: rgb(255 11 55 / 46%);

		--pink-1: 35 12 30;
		--pink-2: 47 19 37;
		--pink-3: 73 23 53;
		--pink-4: 95 25 65;
		--pink-5: 123 14 72;
		--pink-6: 176 15 90;
		--pink-7: 244 34 132;
		--pink-8: 255 139 193;
		--pink-9: 255 208 230;

		--pink-alpha-1: rgb(244 34 132 / 8%);
		--pink-alpha-2: rgb(244 34 132 / 15%);
		--pink-alpha-3: rgb(244 34 132 / 28%);
		--pink-alpha-4: rgb(244 34 132 / 37%);
		--pink-alpha-5: rgb(244 34 132 / 49%);

		--purple-1: 28 21 54;
		--purple-2: 35 28 65;
		--purple-3: 49 36 100;
		--purple-4: 55 33 141;
		--purple-5: 67 41 169;
		--purple-6: 84 59 235;
		--purple-7: 145 117 255;
		--purple-8: 176 156 255;
		--purple-9: 223 214 255;

		--purple-alpha-1: rgb(119 54 255 / 13%);
		--purple-alpha-2: rgb(119 54 255 / 22%);
		--purple-alpha-3: rgb(119 54 255 / 36%);
		--purple-alpha-4: rgb(95 54 255 / 51%);
		--purple-alpha-4: rgb(95 54 255 / 66%);

		--slate-0: 14 19 22;
		--slate-1: 18 23 28;
		--slate-2: 26 33 40;
		--slate-3: 32 40 48;
		--slate-4: 47 59 67;
		--slate-5: 65 80 90;
		--slate-6: 109 127 139;
		--slate-7: 171 186 196;
		--slate-8: 219 230 238;
		--slate-9: 245 249 253;

		--slate-alpha-1: rgb(243 249 255 / 3%);
		--slate-alpha-2: rgb(244 249 254 / 5%);
		--slate-alpha-3: rgb(211 217 253 / 10%);
		--slate-alpha-4: rgb(210 228 254 / 19%);
		--slate-alpha-5: rgb(221 239 253 / 27%);
		--slate-alpha-6: rgb(214 234 250 / 52%);
		--slate-alpha-7: rgb(231 246 253 / 74%);
		--slate-alpha-8: rgb(246 251 254 / 86%);
		--slate-alpha-9: rgb(250 253 255 / 91%);

		--grayscale-0: 0 0 0;
		--grayscale-1: 14 14 14;
		--grayscale-2: 24 24 24;
		--grayscale-3: 35 35 35;
		--grayscale-4: 52 52 52;
		--grayscale-5: 74 74 74;
		--grayscale-6: 141 141 141;
		--grayscale-7: 187 187 187;
		--grayscale-8: 224 224 224;
		--grayscale-9: 244 244 244;
		--grayscale-10: 255 255 255;

		--black-alpha-1: rgb(14 19 22 / 4%);
		--black-alpha-2: rgb(14 19 22 / 8%);
		--black-alpha-3: rgb(14 19 22 / 16%);
		--black-alpha-4: rgb(14 19 22 / 24%);
		--black-alpha-5: rgb(14 19 22 / 32%);
		--black-alpha-6: rgb(14 19 22 / 56%);
		--black-alpha-7: rgb(14 19 22 / 64%);
		--black-alpha-8: rgb(14 19 22 / 80%);
		--black-alpha-9: rgb(14 19 22 / 96%);

		--brand: var(--sky-7);

		--text-primary: 255 255 255;
		--text-secondary: var(--slate-7);
		--text-tertiary: var(--slate-6);
		--text-placeholder: var(--slate-alpha-5);
		--text-link: var(--brand);
		--text-success: var(--green-7);
		--text-warning: var(--yellow-7);
		--text-error: var(--red-7);
		--text-highlight: var(--sky-alpha-5);
		--text-inverse: var(--slate-0);

		--surface-primary: var(--slate-0);
		--surface-secondary: var(--slate-1);
		--surface-tertiary: var(--slate-2);
		--surface-quaternary: var(--slate-3);
		--surface-hover-1: var(--slate-alpha-3);
		--surface-hover-2: var(--slate-alpha-4);
		--surface-success: var(--green-alpha-2);
		--surface-warning: var(--yellow-alpha-2);
		--surface-error: var(--red-alpha-2);
		--surface-modal: #1e2a31;

		--stroke-primary: var(--slate-alpha-4);
		--stroke-secondary: var(--slate-alpha-3);
		--stroke-tertiary: var(--slate-alpha-2);

		color-scheme: dark;
	}
}

html {
	height: 100%;
}

#__next {
	height: 100%;
}

main,
body {
	background: #080808;
}

body {
	color: white;
	height: 100%;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeLegibility;
}

main {
	overflow-y: auto;
}

.scrollbar-hidden {
	-ms-overflow-style: none;
	/* IE and Edge */
	scrollbar-width: none;
	/* Firefox */
}

.scrollbar-hidden::-webkit-scrollbar {
	display: none;
	/* Chrome, Safari, Opera*/
}

.ProseMirror ul {
	padding: 0 1rem;
	list-style: disc;
}

.ProseMirror ol {
	padding: 0 1rem;
	list-style: decimal;
}

.ProseMirror a {
	cursor: pointer;
	color: #55d6f1;
}

.ProseMirror p.is-editor-empty:first-of-type::before {
	color: #797f84;
	content: attr(data-placeholder);
	float: left;
	height: 0;
	pointer-events: none;
}

em-emoji-picker {
	--rgb-background: 37, 38, 40;
	--border-radius: 0.5rem;
	--rgb-accent: 135, 226, 249;
	--font-family: Inter -apple-system, BlinkMacSystemFont, "Helvetica Neue",
		sans-serif;

	border: 1px solid #797f84;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type="number"] {
	-moz-appearance: textfield;
}

::selection {
	@apply bg-blue-500 bg-opacity-70;
}

.focus-visible {
	@apply outline-none ring-2 ring-blue-500 ring-opacity-70;
}

/* Defaults */
@layer base {
	p {
		@apply text-body-lg;
	}

	h1 {
		@apply font-agrandir;
	}

	h2 {
		@apply font-agrandir;
	}

	h3 {
		@apply font-agrandir;
	}

	h4 {
		@apply font-agrandir;
	}

	h5 {
		@apply font-agrandir;
	}

	h6 {
		@apply font-agrandir;
	}
}

@keyframes marquee {
	from {
		transform: translateY(0%);
	}

	to {
		transform: translateY(-100%);
	}
}

@keyframes marquee-inv {
	from {
		transform: translateY(100%);
	}

	to {
		transform: translateY(0%);
	}
}

@keyframes marquee-horizontal {
	from {
		transform: translateX(0%);
	}

	to {
		transform: translateX(-100%);
	}
}

@keyframes marquee-inv-horizontal {
	from {
		transform: translateX(100%);
	}

	to {
		transform: translateX(0%);
	}
}

.animate-marquee {
	animation: marquee 20s linear infinite;
}

.animate-marquee-inv {
	animation: marquee-inv 20s linear infinite;
}

.animate-marquee-horizontal {
	animation: marquee-horizontal 20s linear infinite;
}

.animate-marquee-inv-horizontal {
	animation: marquee-inv-horizontal 20s linear infinite;
}

@keyframes signup-marquee-horizontal {
	from {
		transform: translateX(0%);
	}

	to {
		transform: translateX(-100%);
	}
}

@keyframes signup-marquee-inv-horizontal {
	from {
		transform: translateX(100%);
	}

	to {
		transform: translateX(0%);
	}
}

.animate-signup-marquee-horizontal {
	animation: marquee-horizontal 40s linear infinite;
}

.animate-signup-marquee-inv-horizontal {
	animation: marquee-inv-horizontal 40s linear infinite;
}

.hide-calendar::-webkit-calendar-picker-indicator {
	display: none;
}
